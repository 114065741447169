import { filterFalse } from "@warrenio/utils/collections/filterFalse";
import { useNumberFormatter } from "react-aria";
import { intersperse } from "../../../../utils/src/collections/intersperse.tsx";
import { CurrencyBalance } from "../../components/l10n/Currency.tsx";
import { VatText } from "../../components/VatText.tsx";
import type { EBillingAccount } from "./billingLogic.tsx";
import type { MethodBase } from "./PaymentMethod.tsx";
import { getCalculatedFees } from "./topup/fees.ts";

export function PaymentFees({
    account,
    amount,
    fees,
}: {
    amount: number;
    account: EBillingAccount;
    fees?: MethodBase["fee"];
}) {
    const { vat_percentage } = account.account;

    const percentFmt = useNumberFormatter({
        style: "percent",
        maximumFractionDigits: 10,
    });
    const { baseFee, feePercent, total } = getCalculatedFees(fees, amount, vat_percentage);

    const feeParts = intersperse(
        " + ",
        filterFalse([
            baseFee !== 0 && <CurrencyBalance value={baseFee} />,
            feePercent !== 0 && percentFmt.format(feePercent / 100),
        ]),
    );

    return (
        <div className="font-size-small">
            <p className="color-muted">
                Your total charge will be{" "}
                <b>
                    <CurrencyBalance value={total} />
                </b>
            </p>
            <ul className="color-muted m-0 p-0 pl-4">
                <li>Top up amount</li>
                {feeParts.length > 0 && <li>Payment gateway fee {...feeParts}</li>}
                {vat_percentage !== 0 && (
                    <li>
                        <VatText /> {percentFmt.format(vat_percentage / 100)}
                    </li>
                )}
            </ul>
        </div>
    );
}
